<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">exports</div>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn">
              <v-icon class="text-start">mdi-download</v-icon>
              Export User Information
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn
              class="exportBtn align-start"
              @click="taskAttendanceExport()"
            >
              <v-icon>mdi-download</v-icon>
              Export Task Attendance
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn" @click="attendanceExport()">
              <v-icon>mdi-download</v-icon>
              Export Your attendance
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn">
              <v-icon>mdi-download</v-icon>
              Export Project Information
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn">
              <v-icon>mdi-download</v-icon>
              Export Project Attendance
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn" @click="attendanceBerDayExport()">
              <v-icon>mdi-download</v-icon>
              Export User Attendance Ber Day
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn">
              <v-icon>mdi-download</v-icon>
              Export Your Attendance Ber Day
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn">
              <v-icon>mdi-download</v-icon>
              Data Base Export
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-col md="4" cols="12" class="d-flex">
        <div class="conOfHeader pr-5">
          <a class="exportLink">
            <v-btn class="exportBtn">
              <v-icon>mdi-download</v-icon>
              Country Static Export
            </v-btn>
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  data: () => ({}),

  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    attendanceExport() {
      apiServices.post("attendances/export").then((res) => {
        if (res) {
          this.exportLink = res.data.file;
          const link = document.createElement("a");
          link.href = res.data.file;
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    attendanceBerDayExport() {
      apiServices.post("attendances/berDay/export").then((res) => {
        if (res) {
          this.exportLink = res.data.file;
          const link = document.createElement("a");
          link.href = res.data.file;
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    taskAttendanceExport() {
      apiServices.post("tasks/attendances/export").then((res) => {
        if (res) {
          this.exportLink = res.data.file;
          const link = document.createElement("a");
          link.href = res.data.file;
          document.body.appendChild(link);
          link.click();
        }
      });
    },
  },
  created() {},
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "./_export.scss";
</style>
